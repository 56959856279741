* {
    box-sizing: border-box;

    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
}

html,
body,
#root,
.page_box {
    width: 100%;
    height: 100%;
}

.regular {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

.bold {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

::-webkit-scrollbar {
    /* 滚动条整体部分 */
    width: 6px;
    margin-right: 0px;
}

::-webkit-scrollbar:horizontal {
    height: 4px;
    margin-bottom: 4px;
}

::-webkit-scrollbar-thumb {
    /* 滑块 */
    width: 2px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
    /* 鼠标移入滑块 */
    background: #909090;
}

@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.header-box {
    position: fixed;
    bottom: 0.2rem;
    padding: 0 5.2%;
    display: flex;
    width: 100%;
    justify-content: space-between;
    z-index: 100;
    @media (max-width: 900px) {
        bottom: 0.4rem;
    }
    .left {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 0.14rem;
        color: #000000;
        line-height: 0.16rem;
        opacity: 0.5;
        @media (max-width: 900px) {
            display: none;
        }
    }
    .link_box {
        opacity: 0.5;
        @media (max-width: 900px) {
            flex: 1;
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .text {
            padding: 0 0.2rem;
            font-family: "Roboto", sans-serif;
            font-weight: 400;
            font-size: 0.14rem;
            color: #000000;
            line-height: 0.16rem;
            @media (max-width: 900px) {
                font-size: 0.22rem;
            }
        }
        .ml {
            border-left: 0.01rem solid rgba(0, 0, 0, 0.2);
            border-right: 0.01rem solid rgba(0, 0, 0, 0.2);
        }
    }
}

.page {
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #9c7af0 0%, #ffabff 45%, #ffd3c7 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @media (max-width: 900px) {
        flex-direction: column-reverse;
        height: auto;
        min-height: 100vh;
        padding-bottom: 1rem;
        justify-content: start;
    }
    .left {
        padding: 1.08rem 0.28rem 0.28rem;
        background-color: #fff;
        border-radius: 0.36rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 2;
        margin-right: 10%;
        width: 25%;
        @media (max-width: 900px) {
            background: transparent;
            width: 100%;
            margin-right: 0;
            padding: 0.2rem 0.28rem 0.28rem;
        }
        &::before {
            content: "";
            background: url("../images/border1.png") no-repeat;
            background-size: 100% 100%;
            height: 1.62rem;
            width: 1.62rem;
            position: absolute;
            left: 0;
            top: 0;
            transform: translate(-40%, -40%);
            @media (max-width: 900px) {
                display: none;
            }
        }
        &::after {
            content: "";
            background: #fab3d7;
            border-radius: 0px 0px 0.2rem 0.2rem;
            height: 0.2rem;
            width: 75%;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            z-index: 1;
            @media (max-width: 900px) {
                display: none;
            }
        }
        .logo {
            width: 40%;
            img {
                width: 100%;
            }
            @media (max-width: 900px) {
                display: none;
            }
        }
        .title {
            font-family: "Roboto", sans-serif;
            font-weight: bold;
            font-size: 0.52rem;
            color: #000000;
            line-height: 0.61rem;
            margin-top: 0.38rem;
            @media (max-width: 900px) {
                margin-top: 0;
            }
        }
        .text {
            font-family: "Roboto", sans-serif;
            font-weight: 400;
            font-size: 0.24rem;
            color: #000000;
            line-height: 0.28rem;
            opacity: 0.5;
            margin-top: 0.08rem;
        }
        .app_store {
            width: 100%;
            margin-top: 13%;
            @media (max-width: 900px) {
                margin-top: 0.48rem;
                width: 64%;
                display: flex;
                padding: 0.28rem;
                border-radius: 0.36rem;
                background-color: #fff;
                position: relative;
                &::after {
                    content: "";
                    background: #fab3d7;
                    border-radius: 0px 0px 0.2rem 0.2rem;
                    height: 0.2rem;
                    width: 75%;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 100%);
                    z-index: 1;
                    display: block;
                }
            }
            img {
                width: 100%;
                @media (max-width: 900px) {
                    width: 100%;
                }
            }
        }
    }
    .right {
        width: 40%;
        position: relative;
        z-index: 2;
        @media (max-width: 900px) {
            width: 100%;
        }
        &::before {
            content: "";
            background: url("../images/border2.png") no-repeat;
            background-size: 100% 100%;
            height: 1.62rem;
            width: 1.62rem;
            position: absolute;
            right: 0;
            bottom: 0;
            transform: translate(10%, 10%);
            @media (max-width: 900px) {
                display: none;
            }
        }
        img {
            width: 100%;
        }
    }
}
.pact-page {
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #9c7af0 0%, #ffabff 45%, #ffd3c7 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
       padding: .56rem .36rem 1.25rem;
       
    }
    .pact-box{
        width: 69.5%;
        height: 70.6%;
        background-color: #fff;
        border-radius: 0.36rem;
        padding: 0.4rem 0.84rem 0.7rem;
        position: relative;

        @media (max-width: 900px) {
            width: 100%;
            height: 100%;
          padding: .52rem .4rem .25rem;
        }
        &::before {
            content: "";
            background: url("../images/border1.png") no-repeat;
            background-size: 100% 100%;
            height: 1.62rem;
            width: 1.62rem;
            position: absolute;
            left: 0;
            top: 0;
            transform: translate(-40%, -40%);
            @media (max-width: 900px) {
               display: none;
            }
        }
        &::after {
            content: "";
            background: url("../images/border2.png") no-repeat;
            background-size: 100% 100%;
            height: 1.62rem;
            width: 1.62rem;
            position: absolute;
            right: 0;
            bottom: 0;
            transform: translate(40%, 40%);
            @media (max-width: 900px) {
                display: none;
             }
        }
        .box {
          height: 100%;
            &::after {
                content: "";
                background: #fab3d7;
                border-radius: 0px 0px 0.2rem 0.2rem;
                height: 0.2rem;
                width: 90%;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 100%);
                z-index: 1;
               
            }
            .title {
                font-family: "Roboto", sans-serif;
                font-weight: bold;
                font-size: 0.32rem;
                color: #000000;
                line-height: 0.38rem;
                padding-bottom: .2rem;
            }
            .pact-cont{
                height: 95%;
                overflow: scroll;
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                    display: none;
                }
                p,
                li {
                    font-family: "Roboto", sans-serif;
                    padding-bottom: 0.3rem;
                    font-weight: 400;
                    font-size: 0.16rem;
                    line-height: .24rem;
                    color: #444444;
                    @media (max-width: 900px) {
                        font-size: 0.28rem;
                        line-height: 0.40rem;
                    }
                }
    
                h2 {
                    font-family: "Roboto", sans-serif;
                    font-size: 0.22rem;
                    line-height: 0.25rem;
                    font-weight: 700;
                    color: #000;
                    @extend .bold;
                    margin-bottom: 0.14rem;
                    margin-top: .26rem;
                    border-radius: .12rem;
                    @media (max-width: 900px) {
                        font-size: 0.32rem;
                        line-height: 0.48rem;
                    }
                }
    
                strong {
                    font-weight: bold;
                    color: #000000;
                }
            }
        } 
    }
 
}
